import React from "react";
import { Button } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import posthog from "posthog-js";

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const shineAnimation = keyframes`
  0% { transform: translate(-50%, -50%) rotate(45deg); }
  50% { transform: translate(50%, 50%) rotate(45deg); }
  100% { transform: translate(-50%, -50%) rotate(45deg); }
`;

function HireMeButton() {
  return (
    <Button
      as="a"
      onClick={() => posthog.capture("hire_me")}
      href="mailto:louis@cheminants.com?subject=Let's Make Code Magic Happen!"
      position="relative"
      px="40px"
      py="20px"
      alignSelf="center"
      fontSize="21px"
      fontWeight="bold"
      color="#fff"
      textTransform="uppercase"
      letterSpacing="2px"
      bg="transparent"
      border="4px solid #fff"
      borderRadius="full"
      cursor="pointer"
      overflow="hidden"
      zIndex="1"
      transition="transform 0.2s, box-shadow 0.2s"
      textShadow="0 0 5px rgba(255, 255, 255, 0.5)"
      _hover={{
        transform: "scale(1.05) rotate(1deg)",
        boxShadow: "0 0 25px rgba(255, 255, 255, 1)",
      }}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bg: "linear-gradient(45deg, #000000, #434343, #000000)",
        backgroundSize: "400% 400%",
        zIndex: -2,
        animation: `${gradientAnimation} 5s ease infinite`,
      }}
      _after={{
        content: '""',
        position: "absolute",
        top: "-50%",
        left: "-50%",
        width: "200%",
        height: "200%",
        bg: "radial-gradient(circle at center, rgba(255, 255, 255, 0.3), transparent 70%)",
        transform: "rotate(45deg)",
        animation: `${shineAnimation} 5s ease infinite`,
        pointerEvents: "none",
        zIndex: -2,
      }}
    >
      Hire Me
    </Button>
  );
}

export default HireMeButton;
