import React, { useEffect, useRef } from "react";
import { useTheme } from "next-themes"; // Import from next-themes

const StarsCanvas = () => {
  const canvasRef = useRef(null);
  const { systemTheme: theme } = useTheme(); // Get the current theme from next-themes

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let width = (canvas.width = window.innerWidth);
    let height = (canvas.height = window.innerHeight);
    let stars = [];
    const numStars = 200;

    const handleResize = () => {
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
      drawBackground(); // Redraw the background on resize
    };

    window.addEventListener("resize", handleResize);

    // Function to draw the background gradient based on theme
    const drawBackground = () => {
      const gradient = ctx.createLinearGradient(0, 0, 0, height);

      if (theme === "dark") {
        gradient.addColorStop(0, "#1b2735");
        gradient.addColorStop(1, "#090a0f");
      } else {
        gradient.addColorStop(0, "#ffffff");
        gradient.addColorStop(1, "#e0e0e0");
      }

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, width, height);
    };

    // Initial background drawing
    drawBackground();

    // Initialize stars
    stars = Array.from({ length: numStars }, () => ({
      x: Math.random() * width,
      y: Math.random() * height,
      opacity: Math.random(),
      radius: Math.random() * 1.2,
      speed: Math.random() * 0.02 + 0.01,
    }));

    function animate() {
      // Clear the canvas and redraw the background
      drawBackground();

      stars.forEach((star) => {
        star.opacity += star.speed;
        if (star.opacity > 1) {
          star.opacity = 1;
          star.speed *= -1;
        } else if (star.opacity < 0) {
          star.opacity = 0;
          star.speed *= -1;
        }

        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);

        // Set star color based on theme
        if (theme === "dark") {
          ctx.fillStyle = `rgba(255, 255, 255, ${star.opacity})`; // White stars
        } else {
          ctx.fillStyle = `rgba(0, 0, 0, ${star.opacity})`; // Black stars
        }

        ctx.fill();
      });
      requestAnimationFrame(animate);
    }

    animate();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme]); // Re-run the effect when the theme changes

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
        width: "100%",
        height: "100%",
      }}
    ></canvas>
  );
};

export default StarsCanvas;
