import { VStack, Text, Center } from "@chakra-ui/react";

export const Wrapper = ({ title, children, ...props }) => (
  <VStack
    justify="center"
    bg={{
      _light: "gray.50",
      _dark: "#232329",
    }}
    borderRadius={10}
    w="full"
    padding={{ base: 4, md: 10 }}
    align="flex-start"
    {...props}
  >
    {!!title && <Text fontWeight="bold">{title}</Text>}
    {children}
  </VStack>
);

export const CircleWrapper = ({ children, ...props }) => (
  <Center
    borderRadius="full"
    border="1px solid"
    borderColor={{
      _light: "gray.200",
      _dark: "gray.800",
    }}
    h={"50px"}
    w={"50px"}
    bg={{
      _light: "gray.100",
      _dark: "gray.900",
    }}
    {...props}
  >
    {children}
  </Center>
);
