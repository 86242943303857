import { FaPhone, FaEnvelope } from "react-icons/fa6";
import { Image } from "@chakra-ui/react";
import { CircleWrapper } from "../components";
export const profile = {
  name: "Louis Cheminant",
  title: "Full Stack Developer",
  photo: require("../assets/photo.webp"),
  aboutMe: [
    "Hi, I'm Louis 👋🏻 —a product thinker, designer, and developer with a passion for creating solutions that blend creativity and functionality. Born in France, I now call Buffalo, New York home, and my strong French accent is part of my story.",
    "I've worked in three different countries—Thailand, France, and the USA—helping companies bring their ideas to life, taking projects from 0 to 1. To me, coding is like solving a puzzle: it's about crafting something meaningful within constraints, which is why I love focusing on mobile apps 📱. Their challenges inspire innovation, while websites feel more like canvases for artists.",
    "When I'm not building or designing, I'm outdoors—hiking, biking, running—or exploring the universe through my love for astronomy. I'm also a big Formula 1 fan, a former high-level swimmer, and an Apple enthusiast who dreams of living close to nature 🌲 (despite my fear of spiders).",
    "Organization and focus are the foundation of my work, paired with a proactive mindset. I'm always open to feedback, discussions, and questioning my own ideas. I believe this approach is the key to growth and successful projects.",
    "Let's create something extraordinary together 🚀",
  ],
  contacts: {
    email: {
      name: "Email",
      link: "mailto:louis@cheminants.com",
      logo: (
        <CircleWrapper
          bg={{
            _light: "#438cec30",
            _dark: "#438cec20",
          }}
          borderColor={{
            _light: "#438cec20",
            _dark: "#438cec40",
          }}
          borderWidth={2}
        >
          <FaEnvelope size="20px" color="#438cec" />
        </CircleWrapper>
      ),
    },
    github: {
      name: "GitHub",
      link: "https://github.com/louischeminant",
      logo: (
        <CircleWrapper
          bg={{
            _light: "#582f8830",
            _dark: "#582f8820",
          }}
          borderColor={{
            _light: "#582f8820",
            _dark: "#582f8890",
          }}
          borderWidth={2}
        >
          <Image
            objectFit="contain"
            overflow="hidden"
            w="24px"
            h="24px"
            src={require("../assets/github-icon.png")}
            alt={`LinkedIn Logo`}
            _dark={{ filter: "brightness(2.1) " }}
          />
        </CircleWrapper>
      ),
    },
    linkedin: {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/louischeminant/",
      logo: (
        <CircleWrapper
          bg={{
            _light: "#2c64bc30",
            _dark: "#2c64bc20",
          }}
          borderColor={{
            _light: "#2c64bc20",
            _dark: "#2c64bc40",
          }}
          borderWidth={2}
        >
          <Image
            objectFit="contain"
            overflow="hidden"
            w="22px"
            h="22px"
            src={require("../assets/linkedin-icon.png")}
            alt={`LinkedIn Logo`}
          />
        </CircleWrapper>
      ),
    },
    phone: {
      name: "Phone",
      link: "tel:+17166031937",
      logo: (
        <CircleWrapper
          bg={{
            _light: "#6dd66330",
            _dark: "#6dd66320",
          }}
          borderColor={{
            _light: "#6dd66320",
            _dark: "#6dd66340",
          }}
          borderWidth={2}
        >
          <FaPhone size="18px" color="#6dd663" />
        </CircleWrapper>
      ),
    },
    x: {
      name: "X",
      link: "https://x.com/louischeminant",
      logo: (
        <CircleWrapper
          bg={{
            _light: "#00000010",
            _dark: "#00000020",
          }}
          borderColor={{
            _light: "#00000020",
            _dark: "#000000",
          }}
          borderWidth={2}
        >
          <Image
            objectFit="contain"
            overflow="hidden"
            w="20px"
            h="20px"
            _dark={{ filter: "brightness(0) invert(1)" }}
            src={require("../assets/x-icon.png")}
            alt={`LinkedIn Logo`}
          />
        </CircleWrapper>
      ),
    },
  },
};
export const nationalities = ["🇫🇷", "🇺🇸"];
export const educations = [
  {
    degree: "Master's Degree in Computer Science",
    school: "Epitech",
    startDate: "2013",
    endDate: "2018",
    logoPadding: 0,
    logo: require("../assets/epitech-logo.png"),
    location: "Bordeaux, France 🇫🇷",
  },
  {
    degree: "Computer Science Student",
    school: "SUNY Brockport",
    startDate: "Jan 2018",
    endDate: "May 2018",
    logoPadding: 0,
    logo: require("../assets/brockport.jpg"),
    location: "Brockport, NY 🇺🇸",
  },
];

export const languages = [
  {
    name: "JavaScript",
    logo: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
    experience: 7,
  },
  {
    name: "Python",
    logo: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg",
    experience: 2,
  },
  {
    name: "Swift",
    logo: require("../assets/swift.webp"),
    experience: 5,
  },
  {
    name: "C",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/18/C_Programming_Language.svg",
    experience: 2,
  },
  {
    name: "Objective-C",
    logo: require("../assets/objective-c.png"),
    experience: 5,
  },
  {
    name: "PHP",
    logo: "https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg",
    experience: 6,
  },
  {
    name: "Java",
    logo: "https://upload.wikimedia.org/wikipedia/en/3/30/Java_programming_language_logo.svg",
    experience: 1,
  },
  {
    name: "C++",
    logo: "https://upload.wikimedia.org/wikipedia/commons/1/18/ISO_C%2B%2B_Logo.svg",
    experience: 2,
  },
];

export const hobbies = ["🏊", "🚴", "🏃", "🏕"];

export const categories = [
  {
    title: "Frameworks",
    items: [
      {
        name: "React",
        logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
      },
      {
        name: "SwiftUI",
        logo: "https://developer.apple.com/assets/elements/icons/swiftui/swiftui-96x96_2x.png",
      },
      {
        name: "Vue.js",
        logo: "https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg",
      },
      {
        name: "React Native",
        logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
      },
      {
        name: "Expo",
        logo: require("../assets/expo.jpg"),
      },
    ],
  },
  {
    title: "DevOps",
    items: [
      {
        name: "Terraform",
        logo: require("../assets/terraform.webp"),
      },
      {
        name: "AWS",
        logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
        darkStyle: { filter: "brightness(0) invert(1)" },
      },
      {
        name: "Docker",
        logo: require("../assets/docker.png"),
      },
      {
        name: "GitHub Actions",
        logo: "https://avatars.githubusercontent.com/u/44036562?s=200&v=4",
      },
    ],
  },
  {
    title: "Integrations",
    items: [
      {
        name: "Stripe",
        logo: require("../assets/stripe.webp"),
      },
      {
        name: "Twilio",
        logo: require("../assets/twilio.webp"),
      },
      {
        name: "OpenAI",
        logo: require("../assets/openai.webp"),
        darkStyle: { filter: "brightness(0) invert(1)" },
      },
      {
        name: "AWS MediaConvert",
        logo: require("../assets/mediaconvert.webp"),
      },
    ],
  },
];

export const companies = {
  HelmUX: {
    name: "Helm UX",
    logo: require("../assets/helm.webp"),
    location: "Buffalo, NY 🇺🇸",
    website: "https://www.helmux.com/",
  },
  Weepen: {
    name: "Weepen",
    logo: require("../assets/weepen.webp"),
    location: "Paris, France 🇫🇷",
  },
  EcotradeGroup: {
    name: "Ecotrade",
    logo: require("../assets/ecotrade.webp"),
    location: "Bangkok, Thailand 🇹🇭",
    website: "https://www.ecotradegroup.com/en/",
  },
};

export const experiences = [
  {
    title: "Director & Lead Full-Stack Developer",
    company: companies.HelmUX,
    startDate: "Aug. 2022",
    endDate: "Present",
    details: [
      "Align development with strategic goals in weekly leadership meetings.",
      "Define quarterly objectives to support company growth.",
      "Report key metrics for executive decision-making.",
      "Forecast projects and set milestones for successful execution.",
      "Monitor production to resolve issues quickly and minimize downtime.",
      "Push for R&D, leading development of internal AI tools & products.",
    ],
  },
  {
    title: "Full-Stack Developer",
    company: companies.HelmUX,
    startDate: "May 2020",
    endDate: "Aug. 2022",
    details: [
      "Built and maintained scalable applications using React, React Native, and Express.js.",
      "Guided five companies to launch their first production release.",
      "Designed UI/UX for web and mobile to improve user engagement.",
      "Led development for 10+ products, from planning to deployment.",
      "Standardized code across teams for consistency and projects.",
    ],
  },
  {
    title: "Developer Intern",
    company: companies.HelmUX,
    startDate: "Mar. 2019",
    endDate: "Mar. 2020",
    details: [
      "Updated legacy code for a product that helped companies raise over $1M.",
      "Integrated push notifications and finalized the app for production.",
      "Developed and launched the first mobile app from start to finish with minimal support.",
    ],
  },
  {
    title: "Mobile Manager & iOS Developer/Designer",
    company: companies.Weepen,
    startDate: "Sep. 2016",
    endDate: "Apr. 2019",
    details: [
      "Developed iOS prototypes and applications.",
      "Managed iOS/Android projects from planning to launch.",
      "Collaborated with team members from China, UK, Canada, & Malaysia.",
    ],
  },
  {
    title: "Web Developer Internship",
    company: companies.EcotradeGroup,
    startDate: "Apr. 2017",
    endDate: "Jul. 2017",
    details: [
      "Developed using Symfony and Laravel frameworks.",
      "Contributed design ideas for the company website.",
      "Worked with global teams across different time zones.",
    ],
  },
];

export const projects = [
  {
    icon: require("../assets/app-icon.png"),
    link: null,
    type: "React Native",
    name: "Lifestyle App",
    timeframe: "2023 - 2024",
    details: [
      "Developed API, Admin panel, and iOS/Android apps as the sole developer.",
      "Reached Top 10 in the 'Lifestyle' category at launch.",
      "Achieved 100,000+ users at launch.",
    ],
  },
  {
    icon: require("../assets/aquire-icon.webp"),
    link: "https://apps.apple.com/us/app/aquire-app/id1455519701",
    type: "React Native",
    name: "aQuiRe App",
    timeframe: "2019 - 2024",
    details: [
      "Maintained a single codebase for two apps on iOS, Android, and Windows.",
      "Implemented new features to help the company adapt during COVID.",
      "Enabled 500,000+ quizzes, supporting thousands of daily active users.",
    ],
  },
  {
    icon: require("../assets/academy-icon.webp"),
    link: "https://apps.apple.com/us/app/aquire-construction-academy/id6477888951",
    type: "React Native",
    name: "aQuiRe Construction Academy",
    timeframe: "2023 - 2024",
    details: [
      "Maintained a single codebase for two apps on iOS, Android, and Windows.",
      "Implemented new features to help the company adapt during COVID.",
      "Enabled 500,000+ quizzes, supporting thousands of daily active users.",
    ],
  },
  {
    icon: require("../assets/mysheas-icon.webp"),
    link: "https://apps.apple.com/us/app/my-sheas/id1569447793",
    type: "React Native",
    name: "Shea's App",
    timeframe: "2021",
    details: [
      "Integrated TicketMaster SDK for ticketing features.",
      "Built mobile apps for iOS and Android as a solo developer.",
      "Reached 60,000 users across platforms within one month of launch.",
    ],
  },
  {
    icon: require("../assets/stratic-icon.webp"),
    link: "https://apps.apple.com/us/app/stratic/id6444367941",
    type: "React Native",
    name: "Stratic App",
    timeframe: "2021 - 2023",
    details: [
      "Designed the app from scratch, including design discovery as the client lacked existing designs.",
      "Built a mobile app with mock data to mimic a real app, including loading states and realistic behaviors.",
      "Solo developer for the mobile app initially.",
      "Two years later, led development for building the API and admin panel with another developer.",
    ],
  },
  {
    icon: require("../assets/strideful-icon.webp"),
    link: "https://apps.apple.com/us/app/strideful-walking-running/id6670212904",
    type: "React Native",
    name: "Strideful",
    timeframe: "2024",
    details: [
      "Sole developer for the admin panel, API, and mobile app, built within a 3-month timeframe.",
      "Collaborated closely with a designer and a project manager.",
      "Implemented subscription features and promo code functionality.",
    ],
  },
  {
    icon: require("../assets/powerplayer-icon.webp"),
    link: "https://apps.apple.com/us/app/powerplayer/id6453559636",
    type: "React Native",
    name: "The Power Player",
    timeframe: "2023",
    details: [
      "Lead developer responsible for the API and mobile app.",
      "Designed and implemented support for multiple roles, including players, coaches, and parents.",
      "Completed the project within a 5-month timeframe.",
    ],
  },
  {
    icon: require("../assets/voicelove-icon.webp"),
    link: "https://apps.apple.com/us/app/voicelove/id1571569288",
    type: "React Native",
    name: "VoiceLove",
    timeframe: "2021",
    details: [
      "Lead mobile developer in a team of 6 people.",
      "Focused primarily on API integration and frontend development.",
      "Ensured high performance, robust security, and top-notch quality as the app is used in hospitals.",
    ],
  },
];
