import React, { useEffect, useRef } from "react";

const CircuitBoardCanvas = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    let animationFrameId;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    let width = (canvas.width = window.innerWidth);
    let height = (canvas.height = window.innerHeight);

    // Handle window resize
    const handleResize = () => {
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
      init(); // Reinitialize nodes
    };

    window.addEventListener("resize", handleResize);

    // Node and Line configurations
    const config = {
      nodeCount: 50,
      maxDistance: 150,
      nodeRadius: 2,
      lineColor: "rgba(150, 150, 150, 0.2)",
      nodeColor: "rgba(100, 100, 100, 0.7)",
      backgroundColor: "linear-gradient(white, #f0f0f0)",
    };

    let nodes = [];

    // Initialize nodes
    const init = () => {
      nodes = [];
      for (let i = 0; i < config.nodeCount; i++) {
        nodes.push({
          x: Math.random() * width,
          y: Math.random() * height,
          vx: (Math.random() - 0.5) * 0.5,
          vy: (Math.random() - 0.5) * 0.5,
        });
      }
    };

    // Draw background
    const drawBackground = () => {
      const gradient = ctx.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(0, "#ffffff");
      gradient.addColorStop(1, "#f0f0f0");
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, width, height);
    };

    // Draw nodes
    const drawNodes = () => {
      ctx.fillStyle = config.nodeColor;
      nodes.forEach((node) => {
        ctx.beginPath();
        ctx.arc(node.x, node.y, config.nodeRadius, 0, Math.PI * 2);
        ctx.fill();
      });
    };

    // Draw lines between close nodes
    const drawLines = () => {
      ctx.strokeStyle = config.lineColor;
      for (let i = 0; i < nodes.length; i++) {
        for (let j = i + 1; j < nodes.length; j++) {
          const dx = nodes[i].x - nodes[j].x;
          const dy = nodes[i].y - nodes[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < config.maxDistance) {
            ctx.beginPath();
            ctx.moveTo(nodes[i].x, nodes[i].y);
            ctx.lineTo(nodes[j].x, nodes[j].y);
            ctx.stroke();
          }
        }
      }
    };

    // Update node positions
    const updateNodes = () => {
      nodes.forEach((node) => {
        node.x += node.vx;
        node.y += node.vy;

        // Bounce off edges
        if (node.x <= 0 || node.x >= width) node.vx *= -1;
        if (node.y <= 0 || node.y >= height) node.vy *= -1;
      });
    };

    // Animation loop
    const animate = () => {
      ctx.clearRect(0, 0, width, height);
      drawBackground();
      drawLines();
      drawNodes();
      updateNodes();

      animationFrameId = requestAnimationFrame(animate);
    };

    // Start animation
    init();
    animate();

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default CircuitBoardCanvas;
