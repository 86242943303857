import "./App.css";
import {
  Text,
  VStack,
  Avatar,
  HStack,
  Flex,
  Image,
  Stack,
  Box,
  Circle,
  Button,
  IconButton,
  LinkBox,
  SimpleGrid,
  Container,
} from "@chakra-ui/react";
import {
  profile,
  categories,
  educations,
  hobbies,
  languages,
  nationalities,
  projects,
  experiences,
} from "./data";
import { Wrapper, CircleWrapper } from "./components";
import FancyHireMeButton from "./components/ShinnyButton";
import { useState } from "react";
import { LuExternalLink } from "react-icons/lu";
import posthog from "posthog-js";
import StarsCanvas from "./components/StarsCanvas";
import GridDotsCanvas from "./components/GridDotsCanvas";
import { useTheme } from "next-themes"; // Assuming you're using next-themes

function App() {
  if (
    !window.location.host.includes("127.0.0.1") &&
    !window.location.host.includes("localhost")
  ) {
    posthog.init("phc_JITgmSOLJ2ZWY7YJ8ueMzp8AdwHBxzp5sJojAfJMfUy", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only",
    });
  }

  const { systemTheme: theme } = useTheme();

  return (
    <>
      {theme === "dark" ? (
        <StarsCanvas /> // Your existing stars background for dark mode
      ) : (
        <GridDotsCanvas /> // The new circuit board background for light mode
      )}
      <Container maxW="container.xl">
        <VStack minH="100vh" py={{ base: 4, md: 10 }} gap={10}>
          <Profile />

          <Stack
            direction={{ base: "column", md: "row" }}
            w="full"
            justify="space-between"
            gap={{
              base: 4,
              md: 4,
            }}
          >
            <AboutMe />
          </Stack>

          <WorkExperience />
          <Projects />
          <Education />
          <ProgrammingLanguages />
          <FrameworksAndIntegrations />
        </VStack>
      </Container>
    </>
  );
}

const AboutMe = () => {
  return (
    <VStack
      justify="center"
      bg={{
        _light: "gray.50",
        _dark: "#232329",
      }}
      borderRadius={10}
      w="full"
      padding={{ base: 4, md: 10 }}
      align="flex-start"
      gap={8}
    >
      <Text fontWeight="bold">About Me</Text>
      <VStack align="flex-start" gap={4}>
        {profile.aboutMe.map((paragraph, index) => (
          <Text fontSize="lg" key={index}>
            {paragraph}
          </Text>
        ))}
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        w="full"
        gap={{ base: 4, md: 10 }}
      >
        <VStack align="flex-start">
          <Text fontWeight="bold">Nationalities</Text>
          <HStack>
            {nationalities.map((nationality, index) => (
              <CircleWrapper key={index}>
                <Text fontSize="2xl">{nationality}</Text>
              </CircleWrapper>
            ))}
          </HStack>
        </VStack>
        <VStack align="flex-start">
          <Text fontWeight="bold">Hobbies</Text>
          <HStack>
            {hobbies.map((hobby, index) => (
              <CircleWrapper key={index}>
                {<Text fontSize="2xl">{hobby}</Text>}
              </CircleWrapper>
            ))}
          </HStack>
        </VStack>
      </Stack>
    </VStack>
  );
};

const Contacts = ({ contacts }) => {
  return (
    <HStack gap={4}>
      {Object.values(contacts).map((contact, index) => (
        <LinkBox
          key={index}
          onClick={() => contact.link && window.open(contact.link, "_blank")}
          isExternal
          cursor="pointer"
          transition="transform 0.2s ease-in-out"
          _hover={{
            transform: "scale(1.1)",
          }}
        >
          {contact.logo}
        </LinkBox>
      ))}
    </HStack>
  );
};

const Profile = () => {
  return (
    <VStack
      w="full"
      gap={{
        base: 10,
        md: 4,
      }}
      align={{
        base: "center",
        md: "flex-start",
      }}
      py={10}
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        w="full"
        justify="space-between"
        gap={10}
        alignItems="center"
      >
        <HStack flex={1}>
          <Avatar.Root w="72px" h="72px">
            <Avatar.Image src={profile.photo} />
            <Avatar.Fallback>LC</Avatar.Fallback>
          </Avatar.Root>
          <VStack align="flex-start" gap={0}>
            <Text fontSize="2xl">{profile.name}</Text>
            <Text fontWeight="bold" fontSize="2xl">
              {profile.title}
            </Text>
          </VStack>
        </HStack>
        <VStack gap={2}>
          <FancyHireMeButton />
          <Button
            borderRadius="full"
            variant="ghost"
            size="sm"
            onClick={() => {
              posthog.capture("download_resume");
              window.open("Resume_Louis_Cheminant.pdf");
            }}
          >
            ⬇️ Download Resume
          </Button>
        </VStack>
      </Stack>
      <Contacts contacts={profile.contacts} />
    </VStack>
  );
};

const Education = () => {
  return (
    <Wrapper title="Education">
      <Stack
        w="full"
        direction={{ base: "column", md: "column", lg: "row" }}
        align="flex-start"
        gap={{
          base: 8,
          md: 10,
          lg: "100px",
        }}
      >
        {educations.map((education, index) => (
          <VStack align="flex-start" gap={0} key={index}>
            <HStack>
              <CircleWrapper
                overflow="hidden"
                p={education.logoPadding}
                w="50px"
                h="50px"
              >
                <Image src={education.logo} />
              </CircleWrapper>

              <VStack align="flex-start" gap={0}>
                <Text fontWeight="bold">{education.degree}</Text>
                <Text fontWeight="medium">{education.school}</Text>
                <Text>{`${education.location} - ${education.startDate} - ${education.endDate}`}</Text>
              </VStack>
            </HStack>
          </VStack>
        ))}
      </Stack>
    </Wrapper>
  );
};

const ProgrammingLanguages = () => {
  return (
    <Wrapper title="Programming Languages">
      <Flex wrap="wrap" justify="space-around" w="full" gap={5}>
        {languages.map((language, index) => (
          <HStack
            key={index}
            w={{
              base: "35%",
              md: "20%",
            }}
          >
            <CircleWrapper overflow="hidden" p={2}>
              <Image src={language.logo} alt={`${language.name} Logo`} />
            </CircleWrapper>
            <VStack align="flex-start" gap={0}>
              <Text fontSize="sm" fontWeight="medium">
                {language.name}
              </Text>
              <Text fontWeight="bold">{language.experience} years</Text>
            </VStack>
          </HStack>
        ))}
      </Flex>
    </Wrapper>
  );
};

const FrameworksAndIntegrations = () => {
  return (
    <Wrapper title="Frameworks & Integrations">
      <VStack align="flex-start" gap={8}>
        {categories.map((category, index) => (
          <VStack key={index} align="flex-start" gap={2}>
            <Text fontWeight="medium">{category.title}</Text>
            <HStack wrap="wrap" spacing={5} gap={4}>
              {category.items.map((item, idx) => (
                <HStack key={idx}>
                  <CircleWrapper overflow="hidden" p={2}>
                    <Image
                      src={item.logo}
                      alt={`${item.name} Logo`}
                      _dark={{ ...item.darkStyle }}
                    />
                  </CircleWrapper>
                  <Text textAlign="center" fontWeight="bold">
                    {item.name}
                  </Text>
                </HStack>
              ))}
            </HStack>
          </VStack>
        ))}
      </VStack>
    </Wrapper>
  );
};

const dateContainerWidth = "80px";
const circleContainerWidth = "40px";
const circleSize = "26px";
const dateLeft = `-${
  parseInt(dateContainerWidth) + parseInt(circleContainerWidth)
}px`;
const circleLeft = `-${
  parseInt(circleContainerWidth) / 2 + parseInt(circleSize) / 2
}px`;

const WorkExperience = () => {
  return (
    <Wrapper title="Work Experience">
      <HStack w="full" alignItems="stretch" gap={0}>
        <Box w={dateContainerWidth} />
        <Box w={circleContainerWidth} display="flex" justifyContent="center">
          <Box h="100%" bg={{ _light: "black", _dark: "white" }} w="4px" />
        </Box>
        <Box flex={1}>
          <VStack gap="32px" align="start">
            {experiences.map((experience, index) => (
              <VStack key={index} align="start" w="full" gap={4}>
                <VStack align="flex-start" gap={{ base: 2, md: 1 }} w="full">
                  <Stack
                    justify="space-between"
                    w="full"
                    direction={{ base: "column", md: "row" }}
                    position="relative"
                    gap={0}
                  >
                    <Box position="absolute" left={dateLeft}>
                      <WorkExperienceDate {...experience} />
                    </Box>

                    <Box position="absolute" left={circleLeft}>
                      <WorkExperienceCircle />
                    </Box>
                    <WorkExperienceTitle {...experience} />
                    <WorkExperienceCompany {...experience.company} />
                  </Stack>
                  <WorkExperienceLocation {...experience.company} />
                </VStack>
                <WorkExperienceDetails {...experience} />
              </VStack>
            ))}
          </VStack>
        </Box>
      </HStack>
    </Wrapper>
  );
};

const WorkExperienceCircle = () => {
  return (
    <Circle
      size={circleSize}
      bg={{ _light: "black", _dark: "white" }}
      borderWidth={5}
      borderColor={{ _light: "gray.50", _dark: "gray.900" }}
    />
  );
};

const WorkExperienceDate = ({ startDate, endDate }) => {
  return (
    <Text fontSize="sm" fontWeight="medium" noOfLines={2} w="80px">
      {startDate} - {endDate}
    </Text>
  );
};

const WorkExperienceTitle = ({ title }) => {
  return (
    <Text fontWeight="bold" fontSize="lg">
      {title}
    </Text>
  );
};

const WorkExperienceCompany = ({ website, logo, name }) => {
  return (
    <HStack
      onClick={() => website && window.open(website)}
      cursor={website ? "pointer" : "default"}
      transition="transform 0.2s ease-in-out"
      _hover={
        website && {
          transform: "scale(1.05)",
          bg: {
            _light: "gray.100",
            _dark: "black",
          },
          borderRadius: "15px",
        }
      }
      mr={-3}
      px={3}
      py={1}
    >
      <CircleWrapper h={5} w={5} overflow="hidden">
        <Image src={logo} />
      </CircleWrapper>
      <Text fontWeight="medium">{name}</Text>
    </HStack>
  );
};

const WorkExperienceLocation = ({ location }) => {
  return <Text>{location}</Text>;
};

const WorkExperienceDetails = ({ details = [] }) => {
  return (
    <VStack align="flex-start" spacing={1}>
      {details.map((detail, idx) => (
        <Text key={idx}>• {detail}</Text>
      ))}
    </VStack>
  );
};

const Projects = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Wrapper title="Projects">
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={8} w="100%">
        {projects.map((project, index) => (
          <LinkBox
            onClick={() => project.link && window.open(project.link, "_blank")}
            key={index}
            as="article"
            w="100%"
            p={4}
            borderRadius="15px"
            bg={
              hoveredIndex === index
                ? { _light: "white", _dark: "black" }
                : "transparent"
            }
            onMouseEnter={() => project.link && setHoveredIndex(index)}
            onMouseLeave={() => project.link && setHoveredIndex(null)}
            transition="0.2s ease-in-out"
            _hover={
              project.link && {
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                cursor: "pointer",
              }
            }
          >
            <VStack key={index} align="flex-start" gap={3}>
              <HStack w="full" justify="space-between">
                <HStack>
                  <Image
                    border="1px solid"
                    borderColor={{
                      _light: "gray.200",
                      _dark: "gray.800",
                    }}
                    src={project.icon}
                    alt={`${project.name} Icon`}
                    w="50px"
                    h="50px"
                    borderRadius="15px"
                  />
                  <VStack align="flex-start" gap={0}>
                    <Text fontWeight="bold">{project.name}</Text>
                    <Text>{project.timeframe}</Text>
                  </VStack>
                </HStack>
                <IconButton
                  aria-label="Open project link"
                  color="gray.400"
                  variant="ghost"
                  opacity={hoveredIndex === index ? 1 : 0}
                  transition="opacity 0.2s ease-in-out"
                  _hover={{ bg: "transparent" }}
                >
                  <LuExternalLink />
                </IconButton>
              </HStack>

              <VStack align="flex-start" gap={1}>
                {(project.details || []).map((detail, idx) => (
                  <Text key={idx}>• {detail}</Text>
                ))}
              </VStack>
            </VStack>
          </LinkBox>
        ))}
      </SimpleGrid>
    </Wrapper>
  );
};

export default App;
